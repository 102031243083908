import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "easymde/dist/easymde.min.css";
import App from "./App";
import { SnackbarProvider } from "notistack";
import "./i18n";

const title = process.env.REACT_APP_TITLE || "MIDA-MYS";
document.title = document.title.replace('%REACT_APP_TITLE%', title);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
